import { useDb } from './setup'
import type {Manager} from '~/models/manager';
import cloneDeep from 'lodash/cloneDeep';

const { dbPromise } = useDb()

const insertManagers = async (managers: Manager[]): Promise<boolean> => {
  try {
    // (await dbPromise).clear('contracts')
    const tx = (await dbPromise).transaction('managers', 'readwrite')
    managers.forEach((manager: Manager) => {
      tx.store.put(manager)
    })
    await tx.done
    return true
  } catch (error) {
    console.error('insert Managers error', error)
    return false
  }
}

const managersDb = {
  async getById (id: Manager['Id']): Promise<Manager> {
    try {
      return (await dbPromise).get('managers', id)
    } catch (error) {
      console.error('managersDb: getById', error)
    }
  },
  async getAll (): Promise<Manager[]> {
    try {
      return (await dbPromise).getAll('managers')
    } catch (error) {
      console.error('managersDb: getAll', error)
    }
  },
  async getKeys (): Promise<string[]> {
    try {
      return (await dbPromise).getAllKeys('managers')
    } catch (error) {
      console.error('managersDb: getKeys', error)
    }
  },
  async setById (item: Manager): Promise<string> {
    try {
      return (await dbPromise).put('managers', cloneDeep(item))
    } catch (error) {
      console.error('managersDb: setById', error)
    }
  },
  async delete (id: Manager['Id']): Promise<void> {
    try {
      return (await dbPromise).delete('managers', id)
    } catch (error) {
      console.error('managersDb: delete', error)
    }
  },
  async clear (): Promise<void> {
    try {
      return (await dbPromise).clear('managers')
    } catch (error) {
      console.error('managersDb: clear', error)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useManagersDb = () => ({
  managersDb,
  insertManagers
})
